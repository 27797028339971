@import '../../scss/variables';

.Main {
  height: 80%;

  @include phone {
    padding-bottom: 100px;
  }
}

.Container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  padding-bottom: 38px;
  height: 100%;

  @include ipad {
    padding-right: 20px;
    padding-left: 20px;
    gap: 0;
  }

  @include tablet {
    padding-right: 0;
    padding-left: 0;
  }

  @include phone {
    display: grid;
    grid-template-areas:
      'image'
      'text';
    grid-template-columns: 1fr;
  }

  span {
    font-style: normal;
    font-weight: 500;
    font-size: $fs-14;
    line-height: 1.313rem;
    color: $color-blue-gray;
  }

  h1 {
    font-style: normal;
    font-weight: 900;
    font-size: $fs-40;
    line-height: 3rem;
    color: $color-black;
    margin-top: 7px;
    margin-bottom: 16px;
  }

  p {
    font-style: normal;
    font-weight: 500;
    font-size: $fs-16;
    line-height: 1.5rem;
    letter-spacing: -0.03em;
    color: $color-description;
    margin-bottom: 24px;
  }
}

.TextContainer {
  grid-area: text;
  white-space: break-spaces;
}

.ImageContainer {
  grid-area: image;

  @include ipad {
    > img {
      object-fit: cover;
      width: 700px;
    }
  }

  @include tablet {
    > img {
      width: 500px;
    }
  }

  @include landscape {
    margin-bottom: 10px;

    > img {
      width: 305px;
      height: 203px;
    }
  }

  @include phone {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}