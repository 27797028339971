@import '../../scss/variables';

.Button {
  background-color: $color-accent;
  color: $color-white;
  padding: 16px 20px;
  height: max-content;
  border: none;
  font-style: normal;
  font-weight: 800;
  font-size: $fs-16;
  line-height: 1.5rem;
  border-radius: 53px;
  cursor: pointer;
  transition: 0.2ms;

  &:hover {
    background-color: $color-description;
  }

  &:active {
    background-color: $color-black;
    color: $color-white;
  }

  &:disabled {
    background: rgba(70, 114, 230, 0.2);
  }

  &Outline {
    background-color: transparent;
    border: 1px solid $color-light-grey;
    color: $color-description;

    &:active,
    &:disabled,
    &:hover {
      background-color: transparent;
    }

    &:active,
    &:hover {
      border-color: $color-light-grey;
      background-color: $color-light-grey;
      color: $color-description;
    }

    &:disabled {
      border-color: rgba(70, 114, 230, 0.2);
      color: rgba(70, 114, 230, 0.2);
    }
  }
}