@import '../../scss/variables';

.Section {
  margin-top: 40px;
  margin-bottom: 60px;

  @include phone {
    margin-top: 32px;
    margin-bottom: 40px;
  }

  &Title {
    font-style: normal;
    font-weight: 900;
    font-size: $fs-40;
    line-height: 3rem;
    color: $color-black;
    margin-bottom: 60px;
  }

  &Content {
    display: grid;
    gap: 60px 24px;
    grid-template-columns: repeat(auto-fill, minmax(292px, 1fr));

    @include ipad {
      grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
    }

    @include tablet {
      grid-template-columns: repeat(auto-fill, minmax(293px, 1fr));
    }

    @include landscape {
      grid-template-columns: repeat(auto-fill, minmax(332px, 1fr));
    }

    @include phone {
      grid-template-columns: repeat(auto-fill, minmax(292px, 1fr));
    }
  }
}