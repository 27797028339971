$breakpoint-desktop: 1440px;
$breakpoint-laptop: 1280px;
$breakpoint-ipad: 1024px;
$breakpoint-tablet: 768px;
$breakpoint-phone: 576px;

// From desktop
@mixin laptop {
  @media (max-width: $breakpoint-desktop) {
    @content;
  }
}

// From laptop
@mixin ipad {
  @media (max-width: $breakpoint-laptop) {
    @content;
  }
}

// From ipad
@mixin ipadOnly {
  @media (min-width: $breakpoint-ipad) and (max-width: $breakpoint-laptop) {
    @content;
  }
}

// From ipad
@mixin tablet {
  @media (max-width: $breakpoint-ipad) {
    @content;
  }
}

// From ipad
@mixin tabletOnly {
  @media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-ipad) {
    @content;
  }
}

// From ipad
@mixin landscape {
  @media (max-width: $breakpoint-tablet) {
    @content;
  }
}

// From landscape
@mixin phone {
  @media (max-width: $breakpoint-phone) {
    @content;
  }
}
