@import '../../scss/variables';

.FooterContainer {
}

.FooterContent {
  display: flex;
  justify-content: space-between;
  padding: 48px 0 40px;
  border-top: 1px solid #F8F7FE;

  @include phone {
    flex-direction: column;
    gap: 40px;
  }
}

.Logo {
  margin-bottom: 16px;

  &:hover {
    opacity: 1;
  }
}

.LogoContainer {
  display: flex;
  flex-direction: column;
  margin-top: 8px;

  @include phone {
    margin-top: 0;
  }
}

.PrivacyLink {
  color: $color-accent;
  border-bottom: 1px solid;
  padding-bottom: 2px;

  @include phone {
    width: max-content;
  }
}

.PrivacyLink,
.Address {
  font-style: normal;
  font-weight: 500;
  font-size: $fs-14;
  line-height: 1.313rem;
}

.Address {
  max-width: 250px;
  color: $color-purple;
}

.FooterTitle {
  color: $color-white;
  font-style: normal;
  font-weight: 900;
  font-size: $fs-40;
  line-height: 3rem;

  @media (max-width: 900px) {
    font-size: $fs-34;
  }
}

.FooterDescription {
  font-style: normal;
  font-weight: 500;
  font-size: $fs-16;
  line-height: 1.5rem;
  letter-spacing: -0.03em;
  color: $color-white;
  opacity: 0.8;
  margin-top: 8px;

  @include landscape {
    margin-top: 14px;
  }

  @include phone {
    > br {
      display: none;
    }
  }
}

.FooterBtn {
  margin-top: 17px;
}

.FooterAgent {
  display: flex;
  flex: 1;
  gap: 200px;

  @include ipad {
    gap: 120px;
  }

  @include tablet {
    gap: 74px;
  }

  @media (max-width: 955px) {
    gap: 20px;
  }

  @media (max-width: 852px) {
    gap: 0;
    flex-direction: column;
  }
}