@import '../../scss/variables';

.Container {
  max-width: 292px !important;
}

.Caption {
  left: 30px !important;
  bottom: 24px !important;
  font-size: $fs-26 !important;
  line-height: 2.25rem !important;
}