@import '../../scss/variables';

.Form {
  display: flex;
  flex-direction: column;

  @include phone {
    padding-left: 16px;
    padding-right: 16px;
  }

  input {
    border-radius: 16px;
    border: 1px solid $color-light-grey;
    padding: 15px 14px;
    font-style: normal;
    font-weight: 500;
    font-size: $fs-16;
    line-height: 1.5rem;
    letter-spacing: -0.03em;
    margin-bottom: 16px;
    width: 91%;
    color: $color-black;

    &::placeholder {
      color: $color-blue-gray;
    }

    &:hover {
      border-color: #ABBEFF;
    }

    &:focus,
    &:active {
      border-color: $color-light-grey;
    }

    &:focus-visible {
      color: $color-accent;
      outline: none;
    }
  }

  &Error {
    color: $color-red;
    margin-bottom: 5px;
    margin-left: 16px;
  }

  &ErrorInput {
    margin-bottom: 6px !important;
    border-color: $color-red !important;
  }
}

.ModalTitle {
  font-style: normal;
  font-weight: 900;
  font-size: $fs-32;
  line-height: 2.4rem;
  color: $color-black;
  margin-bottom: 24px;
  max-width: 256px;
  white-space: break-spaces;
}

.ModalDescription {
  font-style: normal;
  font-weight: 500;
  font-size: $fs-20;
  line-height: 1.75rem;
  color: $color-description;
  margin-bottom: 24px;
  white-space: break-spaces;
  letter-spacing: -0.4px;
}

.FormSuccess {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 54px 40px 86px;
  background: $color-white;
  max-width: 474px;

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: $fs-24;
    line-height: 1.813rem;
    color: $color-dark-blue;
    margin-bottom: 16px;
    margin-top: 16px;
  }

  p {
    font-style: normal;
    font-weight: 500;
    font-size: $fs-16;
    line-height: 1.5rem;
    text-align: center;
    letter-spacing: -0.03em;
    color: $color-blue-gray;
    max-width: 253px;
  }
}
