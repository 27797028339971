@import '../../scss/variables';

.ImgWrapper {
  width: 100%;
}

.ImgContainer {
  overflow: hidden;
  border-radius: 24px;

  > img {
    border-radius: 24px;
    width: 100%;
    height: 292px;
    object-fit: cover;

    &:hover {
      transform: scale(1.3);
    }
  }
}

.ImgDescription {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.SubTitleCountry {
  color: $color-black;
  font-size: $fs-16;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2rem;
  letter-spacing: -0.48px;
}

.ImgCountry {
  font-style: normal;
  font-weight: 800;
  font-size: $fs-32;
  line-height: 2.8rem;
  color: $color-black;
  letter-spacing: -0.64px;
}

.ImgProduct {
  font-style: normal;
  font-weight: 700;
  font-size: $fs-26;
  line-height: 2.25rem;
  letter-spacing: -0.02em;
  color: $color-dark-blue;
}