@import '../../scss/variables';

.MainSectionWrapper {
  max-width: 1440px;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 169px;

  @include ipad {
    margin-right: 22px;
    margin-left: 22px;
  }

  @include tablet {
    margin-right: 0;
    margin-left: 0;
  }

  @include phone {
    margin-bottom: 0;
  }
}

.MainSection {
  background-color: #F4F7FF;
  border-radius: 40px;
  margin-bottom: 60px;

  &Container {
    position: relative;
    display: flex;
    justify-content: space-between;

    @include phone {
      justify-content: center;
    }
  }
}

.Title {
  font-style: normal;
  font-weight: 900;
  font-size: $fs-58;
  line-height: 4.151rem;
  color: $color-black;
  margin-top: 73px;
  margin-bottom: 83px;

  > strong {
    font-weight: 900;
    color: $color-accent;
  }

  @include phone {    right: -89px;
    font-size: $fs-36;
    line-height: 2.7rem;
    margin-top: 300px;
    margin-bottom: 24px;
  }
}

.Section {
  margin-top: -185px;
  padding-bottom: 60px;
  text-align: center;
}

.SectionTitle {
  @include tablet {
    white-space: pre;
  }
}

h3 {
  color: $color-black;
  font-style: normal;
  font-weight: 900;
  font-size: $fs-40;
  line-height: 3rem;
  margin-bottom: 64px;
  text-align: left;

  @include phone {
    font-size: $fs-36;
    line-height: 2.563rem;
  }
}

.Products {
  display: flex;
  gap: 24px;
  overflow: auto;
  margin-bottom: 60px;

  > a > div {
    width: 397px;

    @include ipad {
      width: 370px;

      img {
        height: 380px;
        object-fit: cover;
      }
    }

    @include tablet {
      width: 293px;
      img {
        height: 288px;
      }
    }

    @include landscape {
      width: 213px;

      img {
        height: 216px;
      }
    }

    @include phone {
      width: 280px;

      img {
        height: 325px;
      }
    }
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.MobileBtn {
  display: none;

  @include phone {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
  }
}

.ProductContainer {
  margin-top: 30px;
}

.InputContainer {
  display: flex;
  align-items: center;
  gap: 12px;

  @media (max-width: 706px) {
    flex-direction: column;
  }

  @include phone {
    align-items: flex-start;

    > div {
      width: 100%;
    }
  }
}

.ValuesContainer {
  z-index: 99;
}

.ProductTypeContainer {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;

  @include phone {
    overflow: auto;
    gap: 7px;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
}

.ProductTypeBtn {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  border: 2px solid $color-white;
  background-color: $color-white;
  border-radius: 24px;
  padding: 15px 23px;
  color: $color-black;
  cursor: pointer;
  transition: 0.2ms;

  &:hover,
  &Active {
    color: $color-accent;
    border-color: $color-accent;
  }

  @include phone {
    padding-right: 21px;
    padding-left: 21px;
  }
}

.BoatImg,
.VillaImg,
.HelicopterImg {
  position: absolute;
}

.OfferBtn {
  margin-top: 28px;

  @include phone {
    width: 100%;
  }
}

.DatesContainer {
  background-color: $color-white;
  border-radius: 36px;
  padding: 14px 14px 14px 32px;
  margin-bottom: 58px;
  max-width: 613px;
  height: calc(79px - 2 * 11px);

  @media (max-width: 706px) {
    height: 233px;
    margin-bottom: 19px;
    padding: 24px;

    button {
      width: -webkit-fill-available;
    }
  }
}

.DateRangePicker {
  &::after {
    content: url('../../assets/images/arrow.svg');
    position: absolute;
    right: -3px;
    top: 8px;
    transition: transform 0.3s ease;
  }

  &Active::after {
    transition: transform 0.3s ease;
    transform: rotate(180deg);
  }

  @media (max-width: 706px) {
    width: 100%;
  }
}

.Form {
  display: flex;
  flex-direction: column;

  @include phone {
    padding-left: 16px;
    padding-right: 16px;
  }

  input {
    font-family: 'Tilda Sans', serif;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid $color-light-grey;
    font-style: normal;
    font-weight: 500;
    font-size: $fs-16;
    line-height: 1.5rem;
    letter-spacing: -0.03em;
    padding-top: 13px;
    padding-bottom: 13px;
    margin-bottom: 32px;

    &::placeholder {
      color: #8C97BB;
    }
  }
}

.ModalTitle {
  font-style: normal;
  font-weight: 700;
  font-size: $fs-24;
  line-height: 1.813rem;
  color: $color-dark-blue;
  margin-bottom: 24px;
  max-width: 256px;
}

.ModalDescription {
  font-style: normal;
  font-weight: 500;
  font-size: $fs-16;
  line-height: 1.5rem;
  letter-spacing: -0.03em;
  color: $color-blue-gray;
  margin-bottom: 24px;
}

.DateRangePickerContainer {
  position: relative;
  max-width: 190px;

  @media (max-width: 706px) {
    height: 40px;
    margin-bottom: 20px;
    width: 100%;
    max-width: unset;
  }
}

.DateRangePickerLabel {
  position: absolute;
  font-weight: 500;
  font-size: $fs-14;
  line-height: 1.05rem;
  color: #9EA6C0;
  top: -5px;
}

.DateRangePickerEmpty {
  input,
  span {
    background: transparent !important;
    //color: transparent;
    //visibility: hidden;
  }

  input::placeholder {
    color: transparent;
  }
}

.DateRangePickerLabelEmpty {
  background-color: transparent;
  color: #9EA6C0;
  font-style: normal;
  font-weight: 600;
  font-size: $fs-14;
  line-height: 1.05rem;
  letter-spacing: -0.03em;
  top: -5px;
  z-index: 0;
}

.PopularObjectsSection {
  text-align: center;
  padding-bottom: 60px;

  @include phone {
    padding-bottom: 45px;
  }
}

.PopularObjectsHeader {
  display: flex;
  justify-content: space-between;

  @include landscape {
    flex-direction: column;
    gap: 32px;
    margin-bottom: 48px;
  }

  h3 {
    color: $color-black;
    font-size: $fs-50;
    font-style: normal;
    font-weight: 900;
    line-height: 3.75rem;

    @include phone {
      font-size: $fs-36;
      line-height: 2.7rem;
    }

    @include landscape {
      width: auto;
      margin-bottom: 0;
    }
  }
}

.PopularObjectsTypes {
  display: flex;
  gap: 8px;

  button {
    white-space: pre;
  }

  &Active {
    border-color: $color-accent;
    color: $color-accent;
  }

  @include phone {
    overflow: scroll;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
}

.PopularObjectsImages {
  display: grid;
  gap: 60px 24px;
  grid-template-columns: repeat(3, minmax(213px, 397px));
  margin-bottom: 60px;

  @include landscape {
    grid-template-columns: repeat(3, minmax(148px, 250px));
  }

  @include phone {
    display: flex;
    overflow: scroll;
    gap: 12px;
    margin-bottom: 24px;

    a {
      width: 280px;
    }

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @include phone {
      flex-direction: row;
      gap: 12px;
    }

    a {
      overflow: hidden;
      border-radius: 24px;
    }

    img {
      border-radius: 24px;

      @include phone {
        width: 280px;
      }

      &:hover {
        transform: scale(1.3);
      }
    }
  }
}

.PopularImgSmall {
  height: 380px;
  width: -webkit-fill-available;
  object-fit: cover;

  @include tablet {
    height: 300px;
  }

  @include landscape {
    height: 250px;
  }

  @include phone {
    height: 317px;
  }
}

.PopularImgBig {
  height: 605px;
  object-fit: cover;

  @include tablet {
    height: 449px;
  }

  @include landscape {
    height: 360px;
  }

  @include phone {
    height: 317px;
  }
}

.PictureContainer {
  position: relative;

  > img {
    position: absolute;
    top: 2px;
    right: -99px;

    @include ipad {
      right: -200px;
    }

    @include tablet {
      right: -286px;
    }

    @include landscape {
      width: 722px;
      right: -115px;
      top: 224px;
    }

    @include phone {
      width: 800px;
      right: -89px;
      top: -97px;
    }
  }
}

.PictureItem {
  position: absolute;
  background-color: $color-white;
  border-radius: 24px;
  top: 309px;
  right: -30px;

  @include ipad {
    right: -120px;
  }

  @include tablet {
    right: -187px;
    top: 338px;
  }

  @include landscape {
    right: -80px;
    top: 405px;
    border-radius: 7.816px;
  }

  @include phone {
    right: -25px;
    top: 137px;
  }

  h2 {
    color: $color-black;
    font-size: $fs-20;
    font-weight: 900;
    line-height: 1.5rem;
    letter-spacing: -0.7px;
    margin-bottom: 8px;

    @include landscape {
      font-size: $fs-13;
      line-height: 0.977rem;
      margin-bottom: 8px;
    }

    @include phone {
      margin-bottom: 0;
    }
  }

  > div {
    padding: 16px;

    @include landscape {
      padding: 10px;
    }
  }

  span {
    font-size: $fs-14;
    color: #C0BFD0;
    font-weight: 500;
    line-height: 1.045rem;

    @include landscape {
      font-size: $fs-9;
      line-height: 0.681rem;
    }
  }

  > img {
    @include landscape {
      width: 158.93px;
      height: 95.75px;
    }
  }

  &Position {
    top: 54px;
    right: 235px;

    @include ipad {
      right: 139px;
    }

    @include tablet {
      right: 58px;
    }

    @include landscape {
      top: 236px;
      right: 107px;
    }

    @include phone {
      top: -44px;
      right: 138px;
    }
  }
}

.cityInput {
  display: flex;
  flex-direction: column;

  @media (max-width: 706px) {
    height: 40px;
    width: -webkit-fill-available;

    label,
    input {
      width: 100%;
    }
  }

  input {
    font-family: 'Tilda Sans', serif;
    border: none;
    color: $color-black;
    font-size: $fs-16;
    font-weight: 800;
    line-height: 1.2rem;
    display: none;
    padding: 0;
    width: 183px;

    &:focus-visible {
      outline: none;
    }

    @media (max-width: 706px) {
      width: 100%;
    }
  }

  label {
    color: #9EA6C0;
    font-size: $fs-14;
    font-weight: 600;
    line-height: 1.05rem;
    cursor: pointer;
    width: 183px;

    @media (max-width: 706px) {
      width: 100%;
    }
  }

  &Active {
    label {
      margin-bottom: 4px;
    }
    input {
      display: block;
    }
  }
}

.Divider {
  border-right: 1px solid #F0EFF6;
  height: 31px;
  margin-right: 24px;
  margin-left: 24px;

  @media (max-width: 706px) {
    height: 0;
    width: 100%;
    border-top: 1px solid #F0EFF6;
    border-right: 0;
    margin-bottom: 14px;
    margin-top: 19px;
  }
}

.Dates {
  font-size: $fs-16;
  font-weight: 800;
  line-height: 1.2rem;
  position: absolute;
  top: 16px;
  left: 0;
  cursor: pointer;
}