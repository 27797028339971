@import '../../scss/variables';

.ObjWrapper {
  max-width: 1037px;
  margin: auto;
}

.CarouselWrapper {
  border-radius: 32px;
  overflow: hidden;

  img {
    object-fit: cover;
  }
}

.Main {
  display: flex;
  gap: 60px;
  padding-bottom: 60px;
  margin-top: 60px;

  @media (max-width: 1350px) {
    padding-left: 129px;
    padding-right: 129px;
  }

  @media (max-width: 1190px) {
    padding-left: 19px;
    padding-right: 19px;
  }

  @media (max-width: 986px) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @include phone {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.Country {
  font-style: normal;
  font-weight: 500;
  font-size: $fs-14;
  line-height: 1.313rem;
  color: $color-blue-gray;
  margin-bottom: 7px;
}

.Title {
  font-style: normal;
  font-weight: 700;
  font-size: $fs-50;
  line-height: 3.813rem;
  color: $color-dark-blue;
  margin-bottom: 40px;
}

.Description {
  font-style: normal;
  font-weight: 500;
  font-size: $fs-16;
  line-height: 1.5rem;
  letter-spacing: -0.03em;
  color: $color-description;
  margin-bottom: 40px;
}

.Subtitle {
  font-style: normal;
  font-weight: 700;
  font-size: $fs-26;
  line-height: 2.25rem;
  letter-spacing: -0.02em;
  color: $color-black;
  margin-bottom: 24px;
}

.RequestTitle {
  font-style: normal;
  font-weight: 900;
  font-size: $fs-32;
  line-height: 2.4rem;
  color: $color-black;
  margin-bottom: 16px;
}

.RequestDescription {
  font-style: normal;
  font-weight: 500;
  font-size: $fs-18;
  line-height: 1.575rem;
  color: $color-description;
  margin-bottom: 16px;
  letter-spacing: -0.36px;
  white-space: break-spaces;
}

.FormContainer {
  background-color: $color-white;
  max-width: calc(402px - 2 * 33px);
  border-radius: 24px;
  border: 1px solid $color-border;
  padding: 32px;
  height: max-content;
  width: 100%;

  button {
    width: -webkit-fill-available;
  }

  @media (max-width: 986px) {
    display: none;
  }
}

.FormInput {
  border-radius: 16px;
  border: 1px solid $color-light-grey;
  padding: 15px 14px;
  font-style: normal;
  font-weight: 500;
  font-size: $fs-16;
  line-height: 1.5rem;
  letter-spacing: -0.03em;
  width: 91%;
  color: $color-black;

  &::placeholder {
    color: $color-blue-gray;
  }

  &:hover {
    border-color: #ABBEFF;
  }

  &:focus,
  &:active {
    border-color: $color-light-grey;
  }

  &:focus-visible {
    color: $color-accent;
    outline: none;
  }
}

.LeftContainer {
  flex: 1
}

.FacilitiesContainer {
  display: flex;
  gap: 35px;

  @include landscape {
    flex-direction: column;
    gap: 0;
  }

  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: $fs-18;
    line-height: 1.563rem;
    letter-spacing: -0.02em;
    color: $color-black;
    margin-bottom: 21px;
  }

  ul {
    padding-left: 2px;

    @include landscape {
      margin-bottom: 32px;
    }
  }

  li {
    display: flex;
    align-items: center;
    padding: 0;
    font-style: normal;
    font-weight: 500;
    font-size: $fs-16;
    line-height: 1.5rem;
    letter-spacing: -0.03em;
    color: $color-description;
    margin-bottom: 12px;
    border: none;
  }

  li::before {
    content: url('../../assets/images/checkMark.svg');
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  &WithoutTitle {
    gap: 0;
    margin-bottom: 32px;

    @include landscape {
      margin-bottom: 0;
    }
  }
}

.Swiper {
  height: 519px;
}

.MapContainer {
  margin-bottom: 40px;

  > div {
    border-radius: 24px;
    max-width: 575px;
    height: 319px;
  }
}


.DateRangePicker {
  margin-bottom: 20px;
  border: 1px solid $color-light-grey;
  padding: 14px;
  border-radius: 16px;
  width: 100%;

  > div {
    padding: 0;
  }

  > div > div:last-of-type {
    width: 100%;
  }

  input, span {
    font-weight: 500;
    font-size: $fs-16;
    line-height: 1.5rem;
    letter-spacing: -0.03em;
    color: $color-description;
  }

  &::after {
    content: url('../../assets/images/arrow.svg');
    position: absolute;
    right: 10px;
    top: 15px;
    transition: transform 0.3s ease;
  }

  &Active::after {
    transition: transform 0.3s ease;
    transform: rotate(180deg);
  }
}

.OfferBtnContainer {
  display: none;

  @media (max-width: 986px) {
    display: block;
    position: fixed;
    z-index: 99999;
    bottom: 0;
    padding: 12px 16px;
    width: 90%;

    button {
      width: 96%;
    }
  }

  @include phone {
    button {
      width: 92%;
    }
  }
}

.ControlBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-white;
  box-shadow: 0 2px 4px rgba(37, 52, 73, 0.08), 0 8px 24px rgba(37, 52, 73, 0.06);
  border-radius: 51px;
  height: 64px;
  width: 64px;
  cursor: pointer;

  svg path {
    fill: $color-dark-blue;
  }

  &Right {
    transform: rotate(-90deg);
  }

  &Left {
    transform: rotate(90deg);
  }

  @include phone {
    display: none;
  }
}

.FormSuccess {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 54px 40px 86px;
  background: $color-white;
  max-width: 474px;

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: $fs-24;
    line-height: 1.813rem;
    color: $color-dark-blue;
    margin-bottom: 16px;
    margin-top: 16px;
  }

  p {
    font-style: normal;
    font-weight: 500;
    font-size: $fs-16;
    line-height: 1.5rem;
    text-align: center;
    letter-spacing: -0.03em;
    color: $color-blue-gray;
    max-width: 253px;
  }
}

.InputWrapper {
  position: relative;
  margin-bottom: 20px;
}

.Form {
  position: relative;
  &Error {
    position: absolute;
    color: $color-red;
    top: 59px;
    left: 17px;
  }

  &ErrorInput {
    margin-bottom: 6px !important;
    border-color: $color-red !important;
  }

  &Date {
    position: absolute;
    top: 92px;
    left: 16px;
    color: $color-black;
    font-size: $fs-16;
    line-height: 1.5rem;
    letter-spacing: -0.03em;
    font-weight: 500;
  }
}