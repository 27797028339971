@import '../../scss/variables';

.Image {
  height: 292px !important;
  width: 292px !important;
}

.Caption {
  left: 30px !important;
  bottom: 24px !important;
  font-size: $fs-26 !important;
  line-height: 2.25rem !important;
}

.CountryImgContainer {
  display: flex;
  position: relative;
  margin-bottom: 40px;
  border-radius: 32px;
  overflow: hidden;

  &:before {
    position: absolute;
    content: '';
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%), lightgray;
    height: 100%;
    width: 100%;
    opacity: 0.5;
  }

  img {
    width: 100%;
    height: 520px;
    object-fit: cover;

    @include landscape {
      height: 452px;
    }

    @include phone {
      height: 330px;
    }
  }

  > h1 {
    position: absolute;
    margin: auto;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-weight: 900;
    font-size: $fs-70;
    line-height: 5.25rem;
    color: $color-white;
    text-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);

    @include phone {
      font-size: $fs-32;
      line-height: 2.4rem;
    }
  }
}

.InnerPageContainer {
  h1 {
    margin-bottom: 48px;
  }

  @include ipad {
    padding-right: 60px;
    padding-left: 60px;
  }

  @include tablet {
    padding-right: 48px;
    padding-left: 48px;
  }

  @include phone {
    padding-right: 16px;
    padding-left: 16px;

    h1 {
      font-size: $fs-34;
      line-height: 2.563rem;
      margin-bottom: 32px;
    }
  }
}