@import './breakpoints';

$color-black: #212D56;
$color-accent: #4672E6;
$color-blue-gray: #8C97BB;
$color-description: #607184;
$color-purple: #8683A7;
$color-light-grey: #EAEEFC;
$color-border: #ECEAF4;

$color-dark-blue: #212D56;
$color-white: #FFFFFF;
$color-gold: #F8D18E;
$color-red: #E13B27;

$fs-72: 4.5em;
$fs-70: 4.375em;
$fs-68: 4.25em;
$fs-58: 3.625em;
$fs-54: 3.375em;
$fs-50: 3.125em;
$fs-40: 2.5em;
$fs-39: 2.438em;
$fs-36: 2.25em;
$fs-34: 2.125em;
$fs-32: 2em;
$fs-26: 1.625em;
$fs-24: 1.5em;
$fs-20: 1.25em;
$fs-18: 1.125em;
$fs-16: 1em;
$fs-14: 0.875em;
$fs-13: 0.813em;
$fs-12: 0.75em;
$fs-9: 0.568em;
