@import '~scss/variables';
@import '~scss/font/fonts';

body {
  margin: 0;
  font-family: 'Tilda Sans', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

.App, #root {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  color: $color-black;
  text-decoration: none;
  transition: 0.2ms;
}

a:hover {
  color: $color-accent;
}

.container {
  max-width: calc(1440px - 2 * 100px);
  margin: 0 auto;
  padding: 0 100px;

  @include ipad {
    max-width: calc(1440px - 2 * 60px);
    padding-right: 60px;
    padding-left: 60px;
  }

  @include tablet {
    max-width: calc(1440px - 2 * 48px);
    padding-right: 48px;
    padding-left: 48px;
  }

  @include landscape {
    max-width: calc(1440px - 2 * 40px);
    padding-right: 40px;
    padding-left: 40px;
  }

  @include phone {
    max-width: calc(1440px - 2 * 16px);
    padding-right: 16px;
    padding-left: 16px;
  }
}

div.react-daterange-picker__wrapper {
  border: none;
  padding: 0 0 8px;
  color: $color-black;
  font-weight: 800;
  font-size: $fs-16;
  line-height: 1.5rem;
  letter-spacing: -0.03em;
  opacity: 0;
  cursor: pointer;

  input {
    cursor: pointer;
  }
}

div.react-daterange-picker__inputGroup {
  flex-grow: unset;
}

.react-calendar__tile--now {
  background: $color-accent !important;
}

.react-calendar__tile--hasActive {
  background: $color-black !important;
  border-radius: 40px;
  color: $color-white;
}

.react-calendar__tile--active {
  background: $color-accent !important;
  color: $color-white;
}

.react-daterange-picker__range-divider {
  margin-right: 4px;
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  position: absolute;
  top: 45%;
  z-index: 99;
}

.alice-carousel__prev-btn {
  left: 50px;
}

.alice-carousel__next-btn {
  display: flex !important;
  justify-content: flex-end;
  right: 50px;
}

.alice-carousel__stage-item {
  overflow: hidden;
  border-radius: 12px;
}

.menu-open {
  overflow: hidden;
}

.displayFlexClass {
  display: flex;
  flex-direction: column;
}