@font-face {
  font-family: 'Tilda Sans';
  src: url('TildaSans-ExtraBold.eot');
  src: local('Tilda Sans Extra Bold'), local('TildaSans-ExtraBold'),
  url('TildaSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('TildaSans-ExtraBold.woff2') format('woff2'),
  url('TildaSans-ExtraBold.woff') format('woff'),
  url('TildaSans-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Tilda Sans';
  src: url('TildaSansVF.eot');
  src: local('Tilda Sans VF'), local('TildaSansVF'),
  url('TildaSansVF.eot?#iefix') format('embedded-opentype'),
  url('TildaSansVF.woff2') format('woff2'),
  url('TildaSansVF.woff') format('woff'),
  url('TildaSansVF.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Tilda Sans';
  src: url('TildaSans-Medium.eot');
  src: local('Tilda Sans Medium'), local('TildaSans-Medium'),
  url('TildaSans-Medium.eot?#iefix') format('embedded-opentype'),
  url('TildaSans-Medium.woff2') format('woff2'),
  url('TildaSans-Medium.woff') format('woff'),
  url('TildaSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Tilda Sans';
  src: url('TildaSans-Bold.eot');
  src: local('Tilda Sans Bold'), local('TildaSans-Bold'),
  url('TildaSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('TildaSans-Bold.woff2') format('woff2'),
  url('TildaSans-Bold.woff') format('woff'),
  url('TildaSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Tilda Sans';
  src: url('TildaSans-Light.eot');
  src: local('Tilda Sans Light'), local('TildaSans-Light'),
  url('TildaSans-Light.eot?#iefix') format('embedded-opentype'),
  url('TildaSans-Light.woff2') format('woff2'),
  url('TildaSans-Light.woff') format('woff'),
  url('TildaSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Tilda Sans';
  src: url('TildaSans-Black.eot');
  src: local('Tilda Sans Black'), local('TildaSans-Black'),
  url('TildaSans-Black.eot?#iefix') format('embedded-opentype'),
  url('TildaSans-Black.woff2') format('woff2'),
  url('TildaSans-Black.woff') format('woff'),
  url('TildaSans-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Tilda Sans';
  src: url('TildaSans-Regular.eot');
  src: local('Tilda Sans'), local('TildaSans-Regular'),
  url('TildaSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('TildaSans-Regular.woff2') format('woff2'),
  url('TildaSans-Regular.woff') format('woff'),
  url('TildaSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

