@import '../../scss/variables';

.HeaderContent {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;

  @include landscape {
    justify-content: space-between;
    align-items: center;
  }
}

.Logo:hover {
  opacity: 1;
}

.Nav {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;

  > a {
    font-weight: 500;
    font-size: $fs-16;
    line-height: 1.5rem;
    letter-spacing: -0.03em;
  }

  > a:last-of-type {
    margin-right: 100px;
  }

  @include landscape {
    display: none;
  }
}

.LngBtn {
  display: flex;
  align-items: center;
  gap: 3px;
  border:none;
  background-color: transparent;
  color: $color-blue-gray;
  cursor: pointer;
  padding: 0;
  font-family: 'Tilda Sans', serif;
}

.Mobile {
  display: flex;
  align-items: center;
  gap: 16px;
}

.MobileMenu {
  display: none;
  position: relative;

  @include landscape {
    display: block;
  }
}

.MenuIcon {
  cursor: pointer;
  transition: 0.2ms;
  position: relative;
  background-color: $color-blue-gray;
  width: 20px;
  height: 1px;

  &:before,
  &:after {
    transition: transform 0.3s ease;
    content: '';
    position: absolute;
    background-color: $color-blue-gray;
    width: 20px;
    height: 1px;
  }

  &:before {
    top: 5px;
  }

  &:after {
    top: -6px;
  }
}

.MenuIconMain {
  background-color: $color-white;

  &:before,
  &:after {
    transition: transform 0.3s ease;
    content: '';
    position: absolute;
    background-color: $color-white;
  }
}

.MenuIconOpen {
  background-color: transparent;

  &:before {
    top: 0;
    transform: rotate(45deg);
  }

  &:after {
    top: 0;
    transform: rotate(-45deg);
  }
}

.MenuList {
  list-style: none;
  margin-top: 0;
  padding: 33px 16px;
  background-color: $color-white;
  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  height: 100%;
  transition: max-height 0.3s ease-in-out;
  z-index: 99999999999999;


  > li {
    background: transparent;
    border: none;

    > a {
      font-weight: 700;
      font-size: $fs-34;
      line-height: 3rem;
      letter-spacing: -0.02em;
      color: $color-black;
    }
  }
}