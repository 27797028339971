@import '../../scss/variables';

.container {
  display: flex;
}

.modalComp {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(78, 92, 126, 0.69);
  z-index: 999999;
}

.dialog {
  position: fixed;
  left: 50%;
  top: 50%;
  right: auto;
  border-radius: 32px;
  transform: translate(-50%, -50%);
  //width: 20rem;
  background: $color-white;
  padding: 32px;
  min-width: calc(389px - 2 * 32px);

  @include phone {
    min-width: calc(300px - 2 * 16px);
    padding: 16px;
  }
}

.dialog:focus {
  outline: none;
}

.show {
  display: block;
}
.hide {
  display: none;
}

.modalTitle {
  font-weight: 600;
}


.close {
  cursor: pointer;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 24px;
  top: 24px;
  background: #FFFFFF;
  width: 36px;
  height: 36px;
  border-radius: 20px;

  &:hover {
    background: #EAEEFC;
  }
}
