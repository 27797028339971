@import '../../scss/variables';

.Container {
  padding-right: 76px;
  padding-left: 76px;

  @include ipad {
    padding-right: 36px;
    padding-left: 36px;
  }

  @include tablet {
    padding-right: 24px;
    padding-left: 48px;
  }

  @include phone {
    padding-right: 0;
    padding-left: 0;
  }
}

.PageHeader {
  margin-top: 60px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 45px;

  @include landscape {
    grid-template-columns: 1fr;
    grid-gap: 33px;
    margin-bottom: 52px;
  }

  @include phone {
    margin-top: 40px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.Title {
  font-style: normal;
  font-weight: 900;
  font-size: $fs-40;
  line-height: 3rem;
  color: $color-dark-blue;
}

.Description {
  font-style: normal;
  font-weight: 500;
  font-size: $fs-16;
  line-height: 1.5rem;
  letter-spacing: -0.03em;
  color: $color-blue-gray;
  margin-top: 12px;

  @include phone {
    br {
      display: none;
    }
  }
}

.StepContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-bottom: 100px;

  @include ipad {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @include tablet {
    margin-bottom: 48px;
  }

  @include landscape {
    margin-bottom: 56px;
  }

  @include phone {
    grid-template-columns: 1fr;
    margin-bottom: 40px;
  }
}

.Step {
  max-width: 244px;
  padding: 24px 24px 48px 24px;
  background: #FAFCFF;

  @include ipad {
    max-width: unset;
  }
}

.StepNum {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-dark-blue;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  color: $color-white;
  font-weight: 500;
  font-size: $fs-20;
  line-height: 1.75rem;
  letter-spacing: -0.02em;
  margin-bottom: 22px;
}

.StepTitle {
  display: block;
  font-style: normal;
  font-weight: 900;
  font-size: $fs-24;
  line-height: 1.813rem;
  color: $color-dark-blue;
  margin-bottom: 16px;

  br {
    display: none;

    @include phone {
      display: block;
    }
  }
}

.StepDescription {
  font-style: normal;
  font-weight: 500;
  font-size: $fs-16;
  line-height: 1.5rem;
  letter-spacing: -0.03em;
  color: $color-blue-gray;
}