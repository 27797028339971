@import '../../../scss/variables';

.QuestionsSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: #FAFCFF;
  padding: 80px 100px 100px;

  @include ipad {
    padding: 80px 80px 100px;
  }

  @include tablet {
    grid-template-columns: 1fr;
    padding: 80px 48px;
  }

  @include phone {
    padding: 43px 16px;
  }
}

.Title {
  font-style: normal;
  font-weight: 900;
  font-size: $fs-40;
  line-height: 3rem;
  color: $color-dark-blue;

  @include tablet {
    margin-bottom: 64px;
    br {
      display: none;
    }
  }

  @include phone {
    font-weight: 700;
    font-size: $fs-26;
    line-height: 2.25rem;
    margin-bottom: 24px;
  }
}

.Description {
  font-style: normal;
  font-weight: 500;
  font-size: $fs-16;
  line-height: 1.5rem;
  letter-spacing: -0.03em;
  color: $color-blue-gray;
  margin-top: 12px;
  margin-bottom: 28px;

  @include phone {
    br {
      display: none;
    }
  }
}

.DescriptionDesktop,
.ButtonDesktop {
  @include tablet {
    display: none;
  }
}

.QuestionItemContainer {
  padding-top: 16px;
  padding-bottom: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: $fs-20;
  line-height: 1.75rem;
  letter-spacing: -0.02em;
  color: $color-description;
  border-bottom: 1px solid $color-light-grey;
  max-width: 557px;
}

.QuestionItemTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &Open > img {
    transition: transform 0.3s ease;
    transform: rotate(180deg);
  }

  img {
    object-fit: cover;
    margin-left: 80px;
    @include phone {
      margin-left: 60px;
    }
  }
}

.QuestionItemContent {
  height: 0;
  opacity: 0;
  font-weight: 500;
  font-size: $fs-16;
  line-height: 1.5rem;
  letter-spacing: -0.03em;
  color: #607184;
  transition: opacity 0.3s ease;
  visibility: hidden;

  &Open {
    visibility: visible;
    height: auto;
    opacity: 1;
    margin-top: 20px;
  }
}

.QuestionsSectionMobile {
  display: none;

  @include tablet {
    display: flex;
    flex-direction: column;
    margin-top: 64px;

    > button {
      width: max-content;
    }

    .Description {
      max-width: 525px;
      br {
        display: none;
      }
    }
  }
}