@import '../../scss/variables';

.Container {
  max-width: 599px;
  margin: auto;
  padding-bottom: 40px;

  h1,
  h2  {
    font-style: normal;
    font-weight: 900;
    font-size: $fs-40;
    line-height: 3rem;
    color: $color-black;
    margin-top: 60px;
    margin-bottom: 4px;

    @include phone {
      font-size: $fs-24;
      line-height: 2.25rem;
    }
  }

  h1 {
    @include phone {
      margin-top: 32px;
    }
  }

  span {
    font-weight: 500;
    font-size: $fs-16;
    line-height: 1.5rem;
    letter-spacing: -0.03em;
    color: $color-blue-gray;
  }

  p {
    font-weight: 500;
    font-size: $fs-16;
    line-height: 1.5rem;
    letter-spacing: -0.03em;
    color: $color-description;
    margin-top: 24px;

    @include phone {
      margin-top: 16px;
    }
  }
}